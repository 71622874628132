.Dropzone {
  height: 200px;
  width: 200px;
  background-color: #fff;
  border: 4px dashed rgb(187, 186, 186);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-size: 16px;
}

.Icon {
  opacity: 0.3;
  height: 64px;
  width: 64px;
}

.FileInput {
  display: none;
}

.Highlight {
  background-color: rgb(188, 185, 236);
}